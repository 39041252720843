import React, { useState } from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"

const ProductDetails = () => {
  const [eventData, setEventData] = useState({
    domain: "https://cloudcoach.dev",
    currentLivestream: "https://www.youtube.com/embed/8Ud35_KJ6UE",
    currentVideoId: "hQESoSgPDQM",
    nextThreeEvents: {
      first: "",
      second: "",
      third: "",
    },
  })

  return (
    <Layout>
      <SEO title="Livestream" />

      <Navbar />

      <PageBanner pageTitle="Livestream" />

      <div className="shop-details-area ptb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-8">
              {/* <ProductSlider /> */}
              <iframe
                width="800"
                height="500"
                src={eventData.currentLivestream}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-sm-4">
              <iframe
                width="400"
                height="500"
                src="https://www.youtube.com/live_chat?v=8Ud35_KJ6UE&amp;embed_domain=cloudcoach.dev"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-lg-12 col-md-12">
              {/* <ProductsDetailsTabs /> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="shop-details-area ptb-80">
        <div className="row align-items-center">
          <div className="faq-accordion">
            <Accordion allowZeroExpanded preExpanded={['a']}>
                <AccordionItem uuid="a">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <span>
                                Upcoming event #1
                            </span>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>

                        <div className="col-lg-5">
                          <ProductSlider />
                          <iframe
                            width="1200"
                            height="600"
                            src={eventData.currentLivestream}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <ProductsDetailsTabs />
                        </div>

                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
          </div>
        </div>
    </div> */}

      <Footer />
    </Layout>
  )
}

export default ProductDetails
